import {
  faExclamationTriangle,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { swalToastError } from 'helpers/component-utils';
import { React, useEffect, useRef, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { getAtachmentPathsByPcodes } from 'services/attachmentService';
import '../../assets/scss/user.scss';

export default function ProductCodeAlert(props) {
  const btnRef = useRef(null);

  let data = props.data;
  let tableRows = [];
  const [modalAlertShow, setModalAlertShow] = useState(props.show);
  const [imgData, setImgData] = useState([]);

  useEffect(() => {
    setModalAlertShow(props.show);
    if (btnRef) {
      setTimeout(() => {
        btnRef?.current?.focus();
      }, 200);
    }
  }, [props.show, props.queue]);

  useEffect(async () => {
    await props.PopDataOfQueue('isCodeChanged', props.setIsCodeChanged);
  }, [props.queue]);

  useEffect(() => {
    if (data.length > 0) {
      getAtachmentPathsByPcodes(data)
        .then(res => {
          if (res.success && res?.data && res?.data.length > 0) {
            const groupedArray = res?.data.sort((a, b) => {
              if (a.pCode < b.pCode) {
                return -1;
              }
              if (a.pCode > b.pCode) {
                return 1;
              }
              return 0;
            });
            setImgData([...groupedArray]);
          }
        })
        .catch(err => {
          swalToastError('Get Attachment Paths Failed!');
        });
    }
  }, [data]);

  if (imgData.length > 0) {
    imgData.forEach(function (item, index) {
      tableRows.push(
        <tr key={`row${index + 1}`}>
          <td>{item.pCode}</td>
          <td>
            {item.path ? (
              <img
                src={`${process.env.REACT_APP_FILE_API_HOST}/files/download/${process.env.REACT_APP_BUCKET_NAME}?key=${item.path}`}
                style={{
                  objectFit: 'contain',
                  width: `300px`,
                  height: `300px`
                }}
              ></img>
            ) : (
              'No image found'
            )}
          </td>
        </tr>
      );
    });
  }

  const onHide = async e => {
    e.stopPropagation();
    e.preventDefault();
    setModalAlertShow(false);

    // pop data from queue
    props.setIsCodeChanged(false);
    const newQueue = props.queue.filter(item => item != 'isCodeChanged');
    await props.updateQueue(newQueue);
  };

  return (
    <Modal
      show={
        modalAlertShow &&
        props.queue[props.queue.length - 1] === 'isCodeChanged'
      }
      onHide={onHide}
      dialogClassName="modal-60w"
      size="xl"
      backdrop="static"
      autoFocus="true"
    >
      <Modal.Header>
        <Modal.Title>
          Product Code changed - Inny typ listewki - Tipul bitului s-a schimbat
        </Modal.Title>
        <span className="blink">
          <FontAwesomeIcon icon={faExclamationTriangle} size="4x" color="red" />
        </span>
        <FontAwesomeIcon
          icon={faTimes}
          cursor={'pointer'}
          onClick={() => {
            setModalAlertShow(false);
          }}
        ></FontAwesomeIcon>
      </Modal.Header>
      <Modal.Body>
        {/* <input autoFocus={true} hidden={true}></input> */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className="col-sm-3 text-center">Product Code</th>
              <th className="col-sm-6 text-center">Image</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          ref={btnRef}
          id="btnOK"
          className="btn btn-primary"
          onClick={onHide}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
