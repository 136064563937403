import ActionButton from 'components/common/ActionButton';
import IconButton from 'components/common/IconButton';
import AccessDenied from 'components/errors/AccessDenied';
import dayjs from 'dayjs';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import {
  getCurrentUserId,
  getCurrentUser,
  hasAdminPermission
} from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Table } from 'react-bootstrap';
import { FaSortAmountDownAlt, FaSortAmountUpAlt } from 'react-icons/fa';
import { SlCheck, SlClose } from 'react-icons/sl';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getPermission } from 'redux/slices/permissionSlice';
import { sendEmail } from 'services/mailService';
import {
  addStationPCSettings,
  deleteStationPCSettings,
  editStationPCSettings,
  getStationPCSettings,
  getStations
} from 'services/stationService';
import Swal from 'sweetalert2';

const defaultPCSetting = {
  pcName: '',
  stationName: '',
  stationLink: '',
  enabled: false
};

const StationsPCSetting = () => {
  const dispatch = useDispatch();

  const permissionId = useSelector(store => store.permission.permissionId);

  const [pcSettings, setPcSettings] = useState([]);
  const [pc, setPc] = useState(defaultPCSetting);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState('');
  const [stations, setStations] = useState([]);
  const [currentSort, setCurrentSort] = useState('pcName');
  const [isAsc, setIsAsc] = useState(true);

  const getData = () => {
    getStationPCSettings(search)
      .then(res => {
        if (res.success && res.data) {
          setPcSettings(res.data);
        }
      })
      .catch(error => {
        swalToastError('Get Station PC Setting Failed');
      });
  };

  useEffect(() => {
    if (permissionId <= 0) {
      let loggedData = JSON.parse(localStorage.getItem('login-data'));
      if (loggedData && loggedData.isLogged)
        dispatch(getPermission(loggedData.permissionId));
    }

    getData();

    getStations()
      .then(res => {
        if (res.data) {
          setStations(res.data);
        }
      })
      .catch(error => {
        swalToastError('Get Stations Failed!.');
      });
  }, []);

  const onClickEdit = item => {
    setPc(item);
    setShowModal(true);
  };

  const onClickDelete = item => {
    Swal.fire({
      title: `Are You Sure Delete PC Setting ${item.pcName}?`,
      text: "You Won't Be Able To Revert This!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete It!'
    }).then(result => {
      if (result.isConfirmed) {
        deleteStationPCSettings(item.id)
          .then(res => {
            if (res.success && res.data) {
              swalToastSuccess('Delete Station PC Setting Success');
              getData();
            } else {
              swalToastError('Delete Station PC Setting Failed');
            }
          })
          .catch(error => {
            swalToastError('Delete Station PC Setting Failed');
          });
      }
    });
  };

  const handleChangeInput = e => {
    if (e.target.name === 'enabled') {
      setPc({
        ...pc,
        [e.target.name]: e.target.checked
      });
    } else {
      setPc({
        ...pc,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleChangeStation = e => {
    setPc({
      ...pc,
      stationLink: e.target.value,
      stationName:
        stations.find(item => item.stationPath == e.target.value).stationName ||
        ''
    });
  };

  const onSubmit = () => {
    const currentUserId = getCurrentUserId();
    const payload = { ...pc, userId: currentUserId };
    if (pc.id) {
      editStationPCSettings(pc.id, payload)
        .then(res => {
          if (res.success && res.data) {
            swalToastSuccess('Edit Station PC Setting Success');
            getData();
            sendPcSettingEmail(payload, pc.id);
          } else {
            swalToastError('Edit Station PC Setting Failed');
          }
        })
        .catch(error => {
          swalToastError('Edit Station PC Setting Failed');
        });
    } else {
      addStationPCSettings(payload, 0)
        .then(res => {
          if (res.success && res.data) {
            swalToastSuccess('Add Station PC Setting Success');
            getData();
            sendPcSettingEmail(payload);
          } else {
            swalToastError('Add Station PC Setting Failed');
          }
        })
        .catch(error => {
          swalToastError('Add Station PC Setting Failed');
        });
    }
    setShowModal(false);
  };

  const sendPcSettingEmail = (values, id) => {
    const email = {
      subject: 'PC Setting Update Notification',
      app: 'pc-setting-update-notification'
    };
    if (id > 0) {
      email.body = getEmailContent(values, id);
    } else {
      email.body = getEmailContent(values, id);
    }

    sendEmail(email).catch(error => {
      swalToastError('Send Notify Failed!');
    });
  };

  const getEmailContent = (newValues, id) => {
    let oldValues = {};
    if (id > 0) {
      oldValues = pcSettings.filter(pc => pc.id == id)[0];
    }

    let html = `<table style="font-family: Arial; border-collapse: collapse;">
            <thead>
              <tr>
                <th style="width: 250px; border: 1px solid black; padding: 0px 10px border-collapse: collapse;">
                  Field Name
                </th>
                <th style="width: 250px; border: 1px solid black; padding: 0px 10px; border-collapse: collapse;">
                  Old Value
                </th>
                <th style="width: 250px; border: 1px solid black; padding: 0px 10px border-collapse: collapse;">
                  New Value
                </th>
                <th style="width: 250px; border: 1px solid black; padding: 0px 10px; border-collapse: collapse;">
                  Changed By
                </th>
                <th style="width: 250px; border: 1px solid black; padding: 0px 10px; border-collapse: collapse;">
                  Changed Time
                </th>
              </tr>
            </thead>
            <tbody>`;

    Object.keys(newValues).forEach(key => {
      if (key != 'userId') {
        html += `<tr>
                  <td style="width: 250px; border: 1px solid black; padding: 0px 10px; border-collapse: collapse;">${key}</td>
                  <td style="width: 250px; border: 1px solid black; padding: 0px 10px; border-collapse: collapse;">${
                    oldValues[key] || ''
                  }</td>
                  <td style="width: 250px; border: 1px solid black; padding: 0px 10px; border-collapse: collapse;">${
                    newValues[key] || ''
                  }</td>
                  <td style="width: 250px; border: 1px solid black; padding: 0px 10px; border-collapse: collapse;">${
                    getCurrentUser().realName || ''
                  }</td>
                  <td style="width: 250px; border: 1px solid black; padding: 0px 10px; border-collapse: collapse;">${dayjs().format(
                    'DD/MM/YYYY HH:mm'
                  )}</td>
                </tr>`;
      }
    });

    html += `</tbody></table>`;
    return html;
  };

  const onChangSearch = e => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    getData();
  };

  const onSort = (e, column) => {
    e.preventDefault();
    let pcs = [];
    if (column == currentSort) {
      setIsAsc(!isAsc);
      pcs = pcSettings.sort((a, b) => {
        if (!isAsc) {
          return a[column].localeCompare(b[column]);
        } else {
          return b[column].localeCompare(a[column]);
        }
      });
    } else {
      setCurrentSort(column);
      pcs = pcSettings.sort((a, b) => {
        if (isAsc) {
          return a[column].localeCompare(b[column]);
        } else {
          return b[column].localeCompare(a[column]);
        }
      });
    }

    setPcSettings(pcs);
  };

  return permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
    <>
      <div className="g-3 mt-2 row">
        <div className="col-lg-11">
          <Card>
            <Card.Header>
              <div>
                <h5 className="mb-0">Scan Station PC Setting List</h5>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="d-flex align-items-center gap-5 ">
                <Col className="d-flex align-items-center gap-4 col-5">
                  <Form.Label>Search:</Form.Label>
                  <Form.Control
                    type="text"
                    value={search}
                    onChange={onChangSearch}
                    size="xl"
                  ></Form.Control>
                </Col>
                <Col>
                  <Button variant="primary" onClick={onSearch}>
                    Search
                  </Button>
                </Col>
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  onClick={() => {
                    setPc(defaultPCSetting);
                    setShowModal(true);
                  }}
                >
                  <span className="d-none d-sm-inline-block ms-1">
                    New PC Setting
                  </span>
                </IconButton>
              </div>
              <div className="mt-2">
                <Table responsive>
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        style={{ cursor: 'pointer' }}
                        onClick={e => onSort(e, 'pcName')}
                      >
                        <span style={{ marginRight: '10px' }}>PC Name</span>
                        {currentSort == 'pcName' && isAsc && (
                          <FaSortAmountDownAlt />
                        )}
                        {currentSort == 'pcName' && !isAsc && (
                          <FaSortAmountUpAlt />
                        )}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: 'pointer' }}
                        onClick={e => onSort(e, 'stationName')}
                      >
                        <span style={{ marginRight: '10px' }}>
                          Station Name
                        </span>
                        {currentSort == 'stationName' && isAsc && (
                          <FaSortAmountDownAlt />
                        )}
                        {currentSort == 'stationName' && !isAsc && (
                          <FaSortAmountUpAlt />
                        )}
                      </th>
                      <th
                        scope="col"
                        style={{ cursor: 'pointer' }}
                        onClick={e => onSort(e, 'stationLink')}
                      >
                        <span style={{ marginRight: '10px' }}>
                          Station Link
                        </span>
                        {currentSort == 'stationLink' && isAsc && (
                          <FaSortAmountDownAlt />
                        )}
                        {currentSort == 'stationLink' && !isAsc && (
                          <FaSortAmountUpAlt />
                        )}
                      </th>
                      <th scope="col">Enabled</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pcSettings.length > 0 &&
                      pcSettings.map((item, index) => (
                        <tr key={index}>
                          <td>{item.pcName}</td>
                          <td>{item.stationName}</td>
                          <td>{item.stationLink}</td>
                          <td>
                            {item.enabled ? (
                              <SlCheck className="fs-3 text-success" />
                            ) : (
                              <SlClose className="fs-3 text-danger" />
                            )}
                          </td>
                          <td>
                            <div className="d-flex align-item-center">
                              <ActionButton
                                color="red"
                                icon="edit"
                                title="Edit"
                                variant="action"
                                className="p-0 me-3 text-primary"
                                size="lg"
                                onClick={() => onClickEdit(item)}
                              />
                              <ActionButton
                                color="red"
                                icon="trash-alt"
                                title="Delete"
                                variant="action"
                                className="p-0 text-danger"
                                size="lg"
                                onClick={() => onClickDelete(item)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Station PC Setting Translation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Col>
              <Form.Label>PC Name</Form.Label>
              <Form.Control
                autoFocus
                name="pcName"
                type="text"
                value={pc?.pcName}
                onChange={handleChangeInput}
              ></Form.Control>
            </Col>

            <Col>
              <Form.Label>Station</Form.Label>
              <Form.Control
                as={'select'}
                name="station"
                onChange={handleChangeStation}
                defaultValue={pc?.stationLink}
              >
                <option>Select Station Link</option>
                {stations.length > 0 &&
                  stations.map((item, index) => (
                    <option key={index} value={item.stationPath}>
                      {item.stationName}
                    </option>
                  ))}
              </Form.Control>
            </Col>

            <Col className="mt-3">
              <Form.Check
                inline
                label="Enabled"
                name="enabled"
                type="checkbox"
                id={`enabled`}
                checked={pc?.enabled}
                onChange={handleChangeInput}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit}>
            Save
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <AccessDenied />
  );
};
export default StationsPCSetting;
